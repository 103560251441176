import React, { useEffect, useState } from 'react';
import HeaderBar from '../components/HeaderBar';
import banner from '../images/banner.png';
import Carousel from '../components/Carousel';
import ComplicationComponent from '../components/ComplicationComponent';
import WhatIsDLP from '../components/WhatIsDLP';
import DiabetesManagement from '../components/DiabetesManagement';
import quiz from '../images/quiz-1.svg';
import quizDesktop from '../images/quiz-desktop.svg';
import arrow from '../images/Arrow.svg';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import CommonRiskComponent from '../components/CommonRiskComponent';
import JoinNowComponent from '../components/JoinNowComponent';
import RegisterNowComponent from '../components/RegisterNowComponent';
import axios from 'axios';

const LandingPage = () => {
  const [user, setUser] = useState('');

  const getUserIdentifier = () => {
    let userId = localStorage.getItem('dlp-public-user-id');
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem('dlp-public-user-id', userId);
    }
    return userId;
  };
  const userUuid = getUserIdentifier();
  // Use this userId in your API requests

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/api/user/check/user', {
          uuid: userUuid,
        });

        if (response.data) {
          setUser(response.data?.data);
        }
      } catch (error) {
        if (error) {
          message.error('Failed to fetch user data');
        }
      }
    };
    if (userUuid) {
      fetchData();
    }
  }, [userUuid]);

  return (
    <>
      <div className="main-container">
        <center className="container">
          <HeaderBar />
          <div className="mx-3">
            <img
              style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
              src={banner}
              alt="banner"
            />
          </div>
        </center>
        <div className="container">
          <div className="mx-3">
            <section className="my-4">
              <center className="bg-white py-2">
                <div
                  className="bg-yellow py-1 f900 primary-text-color"
                  style={{ fontSize: '1.4rem' }}
                >
                  DID YOU KNOW
                </div>
              </center>
              <Carousel />
            </section>

            <section className="my-4">
              <CommonRiskComponent />
            </section>

            <section className="my-4">
              <ComplicationComponent />
            </section>
            <section className="my-4">
              <WhatIsDLP />
            </section>
            <section className="my-4">
              <DiabetesManagement />
            </section>
            <div
              className="text-white text-center f900"
              style={{ fontSize: '1.3rem' }}
            >
              Take this quiz to find out how much you know about Type 2 diabetes
            </div>
            <div className="bg-yellow-full-width mt-3">
              <div className="d-flex justify-content-between align-items-center height-full">
                <div className='py-2 image-test-yourself'></div>
                {/* <img src={window.size >= 768 ? quizDesktop : quiz} alt="quiz" className="py-2" /> */}
                <div className="d-flex flex-column butone-container">
                  <div className="mt-3 fw-bold mb-2 text-test-yourself">TEST YOURSELF NOW</div>
                  <div className="actual-butone">
                    <Link to="/survey" className='text-deco-none'>
                      <button
                        className="secondary-button px-3"
                        style={{ fontSize: '0.7rem' }}
                      >
                        LET'S START <img src={arrow} alt="arrow" className='arrow-test-yourself' />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <JoinNowComponent userUuid={userUuid} />

            <RegisterNowComponent userUuid={userUuid} />
          </div>
        </div>

        <div className="mt-3" style={{ background: '#fff' }}>
          <div className="container">
            <HeaderBar />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
