import React, { useEffect, useState } from 'react';
import HeaderBar from '../components/HeaderBar';
import banner from '../images/banner.png';
import axios from 'axios';
import { Radio, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import blueBar from '../images/blue-bar.png';
import { useNavigate } from 'react-router-dom';

const SurveyAnswerPage = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [user, setUser] = useState('');
  const navigate = useNavigate();

  const getUserIdentifier = () => {
    let userId = localStorage.getItem('dlp-public-user-id');
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem('dlp-public-user-id', userId);
    }
    return userId;
  };
  const userUuid = getUserIdentifier();
  // Use this userId in your API requests

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/api/user/check/user', {
          uuid: userUuid,
        });

        if (response.data) {
          setUser(response.data?.data);
        }
      } catch (error) {
        if (error) {
          message.error('Failed to fetch user data');
        }
      }
    };
    if (userUuid) {
      fetchData();
    }
  }, [userUuid]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [questionsResponse, answersResponse] = await Promise.all([
          axios.get('/api/survey/withCorrectAns'),
          axios.get(`/api/survey/getAnswerByUserId?user_id=${user?.user_id}`), // Assuming this endpoint returns the user's answers
        ]);

        if (questionsResponse.data) {
          setQuestions(questionsResponse.data.data);
        }

        if (answersResponse.data) {
          setAnswers(answersResponse.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (user) {
      fetchData();
    }
  }, [user]);

  const getOptionClass = (question, option, userAnswer) => {
    if (!userAnswer) return '';

    if (option.option_id === question.correct_option_id) {
      return 'correct-option';
    }

    if (userAnswer.selected_option_id === option.option_id) {
      return 'wrong-option';
    }

    return 'default-option';
  };

  const getRadioStyle = (option, question, userAnswer) => {
    if (!userAnswer) return {};

    if (option.option_id === question.correct_option_id) {
      return {
        color: 'green',
      };
    }

    if (userAnswer.selected_option_id === option.option_id) {
      return {
        borderColor: 'red',
        color: 'red',
      };
    }

    return {};
  };

  // console.log('questions', questions);

  return (
    <div className="main-container">
      <center className="container">
        <HeaderBar />
        <div className="mx-3">
          <img
            style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
            src={banner}
            alt="banner"
          />
        </div>
        <div
          className="primary-text-color fw-bold py-4"
          style={{ fontSize: '1.3rem' }}
        >
          <div>How Familiar Are You With</div>
          <div>Type 2 Diabetes?</div>
        </div>
      </center>
      <div className="container">
        <div
          className="mx-5 text-center"
          style={{
            background: '#fff',
            borderRadius: '1rem',
            boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <div
            style={{
              background: '#F0D474',
              borderRadius: '1rem 1rem 0 0',
              color: '#3B638C',
              fontWeight: '600',
            }}
            className="py-2 "
          >
            YOUR SCORE
          </div>
          <div className="py-4 d-flex align-items-end justify-content-center">
            <div
              style={{
                color: '#3B638C',
                fontWeight: '700',
                fontSize: '3rem',
                alignSelf: 'center',
                marginRight: '1rem',
              }}
              className="mb-2"
            >
              {
                answers?.filter((answer) =>
                  questions.find(
                    (question) =>
                      question.question_id === answer.question_id &&
                      question.correct_option_id === answer.selected_option_id
                  )
                ).length
              }
            </div>
            <div>
              <img src={blueBar} alt="blueBar" style={{ height: '2.5rem' }} />
            </div>
            <div
              style={{
                color: '#3B638C',
                fontWeight: '700',
                fontSize: '2rem',
                alignSelf: 'center',
              }}
              className="mt-2"
            >
              {questions?.length}
            </div>
          </div>
        </div>
        <div className="card mx-3 mt-3">
          {questions?.length
            ? questions.map((question, index) => {
                const userAnswer = answers.find(
                  (answer) => answer.question_id === question.question_id
                );
                return (
                  <div
                    key={question.question_id}
                    className="my-4 primary-text-color px-3 question-container"
                  >
                    <div className="d-flex question-text">
                      <div>{index + 1}. </div>
                      <div>{question.question_text}</div>
                    </div>
                    <Radio.Group
                      value={userAnswer ? userAnswer.selected_option_id : null}
                      className="large-radio"
                    >
                      {question.Options.map((option) => {
                        return (
                          <div
                            key={option.option_id}
                            className={`radio-container ${getOptionClass(
                              question,
                              option,
                              userAnswer
                            )}`}
                          >
                            <Radio
                              value={option.option_id}
                              style={getRadioStyle(
                                option,
                                question,
                                userAnswer
                              )}
                            >
                              <span
                                className="primary-text-color ms-2"
                                style={getRadioStyle(
                                  option,
                                  question,
                                  userAnswer
                                )}
                              >
                                {option.option_text}
                              </span>
                            </Radio>
                          </div>
                        );
                      })}
                    </Radio.Group>
                    <div
                      style={{ lineHeight: '1.2rem', fontSize: '0.9rem' }}
                      className="mt-3"
                    >
                      {' '}
                      {question?.description}{' '}
                    </div>
                  </div>
                );
              })
            : null}
        </div>

        <div className="mx-3 py-3">
          <button
            onClick={() => navigate('/')}
            className="custom-primary-button"
          >
            Back to Homepage
          </button>
        </div>
      </div>

      <div className="mt-3" style={{ background: '#fff' }}>
        <div className="container">
          <HeaderBar />
        </div>
      </div>
    </div>
  );
};

export default SurveyAnswerPage;
