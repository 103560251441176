import React, { useEffect, useState } from 'react';
import HeaderBar from '../components/HeaderBar';
import banner from '../images/banner.png';
import axios from 'axios';
import { Form, Radio, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

const SurveyPage = () => {
  const [questions, setQuestions] = useState([]);
  const [user, setUser] = useState('');

  const getUserIdentifier = () => {
    let userId = localStorage.getItem('dlp-public-user-id');
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem('dlp-public-user-id', userId);
    }
    return userId;
  };
  const userUuid = getUserIdentifier();
  // Use this userId in your API requests

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/api/user/check/user', {
          uuid: userUuid,
        });

        if (response.data) {
          setUser(response.data?.data);
        }
      } catch (error) {
        if (error) {
          message.error('Failed to fetch user data');
        }
      }
    };
    if (userUuid) {
      fetchData();
    }
  }, [userUuid]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/survey/questions');

        if (response.data) {
          setQuestions(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/survey/questions');

        if (response.data) {
          setQuestions(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const answers = [];
    for (const [key, value] of Object.entries(values)) {
      const questionId = parseInt(key.split('_')[1]);
      answers.push({ questionId, selectedOptionId: value });
    }

    if (!user) {
      message.error('Server side error!');
      return;
    }

    axios
      .post('/api/survey/answer', { userId: user?.user_id, answers: answers })
      .then((response) => {
        if (response.data.message) {
          message.success(response.data.message);
        }

        setTimeout(() => {
          return (window.location.href = '/survey-answer');
        }, 1500);
      })
      .catch((error) => {
        if (error.response) {
          // console.log('error.response', error.response);
          message.error(error.response?.data.error);
        }
      });
  };

  // console.log('questions', questions);

  return (
    <div className="main-container ">
      <center className="container ">
        <HeaderBar />
        <div className="mx-3">
          <img
            style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
            src={banner}
            alt="banner"
          />
        </div>
        <div
          className="primary-text-color fw-bold py-4"
          style={{ fontSize: '1.3rem' }}
        >
          <div>How Familiar Are You With </div>
          <div>Type 2 Diabetes?</div>
        </div>
      </center>
      <div className="container">
        <Form form={form} name="survey" onFinish={onFinish} layout="vertical">
          <div className="card mx-3 pt-3">
            {questions?.length
              ? questions.map((question, index) => (
                  <div key={index} className="primary-text-color px-3 question-container ">
                    <Form.Item
                      name={`question_${question.question_id}`}
                      label={
                        <div className="d-flex question-text">
                          <div>{index + 1}. </div>
                          <div className="ms-2">{question.question_text}</div>
                        </div>
                      }
                      rules={[
                        {
                          required: true,
                          message: 'This question is required',
                        },
                      ]}
                      required={false}
                    >
                      <Radio.Group className="large-radio">
                        {question.Options.map((option, idx) => (
                          <div key={idx} className="radio-container">
                            <Radio
                              key={option.option_id}
                              value={option.option_id}
                            >
                              <span className="primary-text-color ms-2">
                                {option.option_text}
                              </span>
                            </Radio>
                          </div>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </div>
                ))
              : null}
          </div>

          <div className="mx-3 py-3">
            <button htmltype="submit" className="custom-primary-button">
              Submit
            </button>
          </div>
        </Form>
      </div>
      <div style={{ background: '#fff' }}>
        <div className="container">
          <HeaderBar />
        </div>
      </div>
    </div>
  );
};

export default SurveyPage;
