import React from 'react';
import dlp1 from '../images/dlp-1.svg';
import dlp2 from '../images/dlp-2.svg';
import dlp3 from '../images/dlp-3.svg';

const WhatIsDLP = () => {
  const imgDLP = [
    { src: dlp1, alt: 'dlp-1' },
    { src: dlp2, alt: 'dlp-2' },
    { src: dlp3, alt: 'dlp-3' },
  ];
  return (
    <div className="text-center custom-card p-3">
      <div style={{ color: '#F18831' }} className="f700 text-uppercase">
        <div>Introducing the Diabetes lifestyle programme</div>
      </div>

      <div
        className="f700 text-uppercase primary-text-color py-3"
        style={{ fontSize: '1.4rem' }}
      >
        WHAT IS DLP?
      </div>
      <div className="primary-text-color px-3 whatdlp-desc">
        <p className='f500'>
          Diabetes Lifestyle Programme (DLP), is a programme that focuses on
          diabetes management through a structured & holistic lifestyle
          approach.
        </p>
        <p className='f500'>
          The programme is built upon providing comprehensive training on
          diabetes lifestyle management for healthcare professionals (HCPs). It
          also provides a digital platform, the DLP Digital Education & Support
          Web-based App for people with diabetes, that brings HCPs and people
          with diabetes together, facilitating lifestyle changes among people
          with diabetes for optimal diabetes care.
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {imgDLP.map((img, index) => (
          <img
            key={index}
            src={img.src}
            alt={img.alt}
            style={{ height: 'auto', margin: '1%' }}
            className={`${
              index === 1 ? 'whatisdlp-img-bigger' : 'whatisdlp-img'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default WhatIsDLP;
