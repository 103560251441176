import React from 'react';

// import image
import comp1 from '../images/complication-1.svg';
import comp2 from '../images/complication-2.svg';
import comp3 from '../images/complication-3.svg';
import comp4 from '../images/complication-4.svg';
import comp5 from '../images/complication-5.svg';
import comp6 from '../images/complication-6.svg';
import comp7 from '../images/complication-7.svg';

const ComplicationComponent = () => {
  const imgComplication = [
    { src: comp1, alt: 'complication-1' },
    { src: comp2, alt: 'complication-2' },
    { src: comp3, alt: 'complication-3' },
    { src: comp4, alt: 'complication-4' },
    { src: comp5, alt: 'complication-5' },
    { src: comp6, alt: 'complication-6' },
    { src: comp7, alt: 'complication-7' },
  ];
  return (
    <div className="custom-card p-3">
      <div className="text-center f600  primary-text-color">
        <div style={{ fontSize: '1.4rem' }}>
          POORLY MANAGED DIABETES CAN RESULT IN COMPLICATIONS
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          padding: '2%',
        }}
        className="mt-3"
      >
        {imgComplication.map((img, index) => (
          <img
            key={index}
            src={img.src}
            alt={img.alt}
            style={{ height: 'auto', margin: '4%', objectFit: 'contain' }}
            className={index == '6' ? 'complication-img-7' : 'complication-img'}
          />
        ))}
      </div>
    </div>
  );
};

export default ComplicationComponent;
